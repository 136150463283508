import React from 'react';
import './SimsolaLoader.css';
import { useLoader } from '../../Utils/ContextHelper/LoaderContext';

const SimsolaLoader = () => {
    const { isLoading } = useLoader();

    if (!isLoading) return null;
    return (
        <div className="simsola-loader">
            <div className="loader-text">Simsola</div>
            <div className="loader-dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default SimsolaLoader;
